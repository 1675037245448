import React from "react"

export default props => (
  <table className={props.TableClass}>
    <thead>
      <tr>
        <th colSpan="2">
          {props.isFoot ? <h6>Öffnungszeiten</h6> : <h5>Öffnungszeiten</h5>}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Montag</td>
        <td className="text-right">08:30 – 17:00</td>
      </tr>
      <tr>
        <td>Dienstag</td>
        <td className="text-right">08:30 – 17:00</td>
      </tr>
      <tr>
        <td>Mittwoch</td>
        <td className="text-right">08:30 – 17:00</td>
      </tr>
      <tr>
        <td>Donnerstag</td>
        <td className="text-right">08:30 – 17:00</td>
      </tr>
      <tr>
        <td>Freitag</td>
        <td className="text-right">08:30 – 15:00</td>
      </tr>
      <tr>
        <td>Samstag</td>
        <td className="text-right">Wenn auf, dann auf!</td>
      </tr>
      <tr>
        <td>Sonntag</td>
        <td className="text-right">geschlossen</td>
      </tr>
    </tbody>
  </table>
)
