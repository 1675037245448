import React from "react"
import { Helmet } from "react-helmet"

import favicon16 from "../assets/favicon/favicon16.png"
import favicon32 from "../assets/favicon/favicon32.png"
import favicon64 from "../assets/favicon/favicon64.png"

export default () => (
  <Helmet
    link={[
      {
        rel: "icon",
        type: "image/png",
        sizes: "16x16",
        href: `${favicon16}`,
      },
      {
        rel: "icon",
        type: "image/png",
        sizes: "32x32",
        href: `${favicon32}`,
      },
      { rel: "shortcut icon", type: "image/png", href: `${favicon64}` },
    ]}
  >
    <html lang="de" />
    <meta charSet="utf-8" />
    <title>Dachdeckerei Michael Bürgel e.K., Ihr Dachdecker seit 1956</title>
    <link rel="canonical" href="https://www.dachdeckerei-buergel.de" />
    <meta name="robots" CONTENT="index, follow" />
    <meta
      name="description"
      lang="de"
      content="Offizielle Webseite der Dachdeckerei Michael Bürgel aus Dormagen. Wir versteht sich uns kompetenter Partner von Architekten und Bauingenieuren. So stehen wir schon in der Planungsphase eines Bauvorhabens mit Rat und Tat zur Seite. Gemeinsam entstehen so Lösungen die nicht nur optisch ansprechend, sind sondern auch bautechnisch und wirtschaftlich höchsten Ansprüchen genügen."
    />
    <meta name="author" content="Nils-Tristan Bürgel" />
    <meta
      name="keywords"
      lang="de"
      content="Dachdecker, Dachdeckerei, Michael, Bürgel, Buergel, Michael Bürgel,Michael Buergel, Dormagen, Kran, Kranverleih, Kupfer, Rheinzink, Photovoltaik, Fotovoltaik, Steildach, Flachdach, Fassadenverkleidung, Hallendacheindeckungen, Balkon, Balkonabichtung, Balkonbeschichtungen, Terrasse, Terrassenabdichtung, Kellerabdichtungen, Fassadenverkleidungen, Wärmedämmung, Dachfenster, Kamin, Dachbegrünung, Solar, Solartechnik, Holzbau, Gerüstbau, Kleinreparaturen, Hackenbroich, Gewerbegebiet, Kruppstr, Kruppstraße, Neuss, Köln, Düsseldorf, Dachsanierung, Neueindeckung"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </Helmet>
)
