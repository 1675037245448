import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { Link } from "gatsby"
import Rating from "../components/starRating"
import OpenHoursTable from "../components/tableOpeningHours"

import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
  FaInstagram,
  FaFacebook,
} from "react-icons/fa"

import "../styles/footer.css"

import logoLeft from "../assets/footerLeft.png"
import logoRight from "../assets/footerRight.png"

export default () => (
  <div className="footer-container">
    <Container fluid className="footer-middle" />
    <Container fluid className="footer-top">
      <Row className="show-grid">
        <Col
          xs={12}
          sm={12}
          md={true}
          className="d-flex align-items-center text-center footer-center"
        >
          <div>
            <Image src={logoLeft} fluid className="footer-pic" />
            <p />
            <h6 className="footer-red">Innungs-Meisterbetrieb</h6>
            <p />
          </div>
        </Col>
        <Col xs={12} sm={12} md={true} className="text-center">
          <table className="footer-table">
            <thead>
              <tr>
                <th colSpan="2">
                  <h6>Kontakt</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FaMapMarkerAlt />
                </td>
                <td>Kruppstr. 14</td>
              </tr>
              <tr>
                <td />
                <td>41540 Dormagen</td>
              </tr>
              <tr>
                <td>
                  <FaPhone className="mirror" />
                </td>
                <td>
                  <a className="white-link" href="tel:0049213363294">
                    02133 / 63294
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <FaEnvelope />
                </td>
                <td>
                  <a
                    className="white-link"
                    href="mailto:info@dachdeckerei-buergel.de"
                  >
                    info@dachdeckerei-buergel.de
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          <table className="footer-table">
            <thead>
              <tr>
                <th>
                  <h6>Social Media</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a
                    className="white-link"
                    href="https://www.facebook.com/DachdeckereiMichaelBuergel"
                  >
                    <FaFacebook size="3em" />
                  </a>
                  <a
                    className="white-link"
                    href="https://www.instagram.com/dachdeckerei_michaelbuergel/"
                  >
                    <FaInstagram size="3em" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          <h6 className="footer-red h6">Bitte bewerten Sie uns:</h6>
          <Rating />
        </Col>

        <Col xs={12} sm={12} md={true} className="text-center">
          <OpenHoursTable TableClass="footer-table" isFoot={true} />
          <p />
        </Col>

        <Col xs={12} sm={12} md={true} className="text-center">
          <table className="footer-table">
            <thead>
              <tr>
                <th>
                  <h6>Navigation</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Link className="white-link" to="/">
                    Startseite
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link className="white-link" to="/about">
                    Über uns
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link className="white-link" to="/leistungen">
                    Leistungen
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link className="white-link" to="/dachcheck">
                    Dach-Check
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link className="white-link" to="/meindach">
                    Mein neues Dach
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link className="white-link" to="/kranverleih">
                    Kranverleih
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link className="white-link" to="/kontakt">
                    Kontakt
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link className="white-link" to="/jobs">
                    Jobs
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
          <p />
        </Col>

        <Col
          xs={12}
          sm={12}
          md={true}
          className="d-flex align-items-center footer-center"
        >
          <Image src={logoRight} fluid className="footer-pic" />
          <p />
        </Col>
      </Row>
    </Container>
    <Container fluid className="footer-middle" />
    <Container fluid className="footer-bottom">
      <Row className="show-grid">
        <Col xs={12} sm={6} className="text-left">
          &copy; Dachdeckerei Michael Bürgel e.K.
        </Col>
        <Col xs={12} sm={6} className="text-right">
          <Link className="white-link" to="/impressum">
            Impressum
          </Link>{" "}
          |{" "}
          <Link className="white-link" to="/datenschutz">
            Datenschutzerklärung
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
)
