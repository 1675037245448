import React, { Component } from "react"
import Ratings from "react-ratings-declarative"

export default class starRating extends Component {
  constructor() {
    super()
    this.changeRating = this.changeRating.bind(this)
    this.state = {
      rating: 0,
    }
  }

  changeRating(newRating) {
    this.setState({
      rating: newRating,
    })
    if (newRating >= 5)
      window.open(
        "https://search.google.com/local/writereview?placeid=ChIJLTDdKlQxv0cRzyZlIuTiB7o"
      )
  }

  render() {
    return (
      <Ratings
        rating={this.state.rating}
        changeRating={this.changeRating}
        widgetRatedColors="yellow"
        widgetDimensions="40px"
      >
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
      </Ratings>
    )
  }
}
